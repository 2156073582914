<template>
  <div class="notifications-page px-2">
    <div class="d-flex px-2">
      <div class="col title font-weight-bold h5 my-4 mt-2">اﻹشعارات</div>
        <a href="#" @click.prevent="markAllAsRead" class="pt-2 card-secondary-lable font-weight-bold">
          <i class="la la-spinner la-spin" v-if="markAsReadLoading"></i>
          <i class="la la-check-circle" v-else></i>
           تحديد الكل كمقروء</a>
      <div>
      </div>
    </div>
    
    <div class="hide-scrollbar ps-2" v-if="$apollo.queries.me.loading">
        <NotificationCardLoader />
    </div>

    <div class="px-2 justify-content-md-center text-center text-light mx-0">
        <div class="" v-for="notification in me?.notifications.data" :key="notification.id">
            <NotificationCard :notification="notification" />
        </div>
    </div>

    <div class="text-center px-2" v-if="!$apollo.queries.me.loading && !me.notifications.data.length">
      <div class="main-card p-5">
        لا توجد إشعارات
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import NotificationCard from "../../components/NotificationCard.vue";
import NotificationCardLoader from "../../components/Loader/NotificationCardLoader.vue";
import { useToast } from 'vue-toastification';

export default {
  components: { NotificationCard, NotificationCardLoader },

  setup () {
    const toast = useToast()

    return {
      toast
    }
  },

  data: () => {
    return {
      markAsReadLoading: false,
      me: null,
    };
  },

  methods: {

    markAllAsRead() {
      this.markAsReadLoading = true
      this.$apollo.mutate({
          mutation: gql`
              mutation {
                markAllNotificationsAsRead
              }
          `,
      })
      .then((res) => {
          // Result
          console.log(res.data.markAllNotificationsAsRead)
          this.$apollo.queries.me.refetch()
          this.$store.commit('UPDATE_UNREAD_NOTIFICATIONS_COUNT', 0)
          this.toast.success('تم تحديد الكل كمقروء')
          this.markAsReadLoading = false
      }).catch((error) => {
          // Error
          console.error(error)
          this.markAsReadLoading = false
      });

    }
  },

  apollo: {
    me: {
      query: gql`
        query Provider($first: Int!) {
          me {
            notifications (first: $first) {
              data {
                id
                read_at
                created_at

                data {
                  message
                  target_id
                  target_type
                }
              }
            }
          }
        }
      `,
      variables () {
        return {
          first: 100,
        }
      }
    },
  },
};
</script>

<style>

</style>
