<template>
  <div>
    <div class="item shadow-sm p-2 mb-2 card-hover card-secondary-lable " :class="{'active': notification.read_at == null }">
      <div class="d-flex">
        <div class="d-flex flex-column justify-content-center">
          <span><i class="la la-bell h1 pe-3 ps-1" :class="{'text-success': notification.read_at == null }"></i></span>
        </div>
        <div class="col">
          <div class="text-start">{{ notification.created_at }} </div>
          <div class="text-dark text-start">{{ notification.data.message }}</div>
          <div class="text-start py-2" v-if="notification.data.target_type != null">
            <small>
              <router-link :to="{ name: 'Category', params: { id: notification.data.target_id } }" class="btn btn-default shadow-1 btn-sm" v-if="notification.data.target_type == 'App\\Models\\Category'">عرض التصنيف <i class="la la-angle-left ms-2"></i></router-link>
              <router-link :to="{ name: 'Program', params: { id: notification.data.target_id } }" class="btn btn-default shadow-1 btn-sm" v-if="notification.data.target_type == 'App\\Models\\Program'" >عرض البرنامج <i class="la la-angle-left ms-2"></i></router-link>
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['notification'],
};
</script>

<style scoped>

  .active {
    background-color: rgba(140, 255, 147, 0.1);
  }

</style>
