<template>
    <div class="col-md-12 mb-2" v-for="i in 4" :key="i">
        <div class="shadow-sm">
            <ContentLoader width="400" height="80" >
            <rect x="357" y="25" rx="7" ry="7" width="30" height="30" />
            <rect x="193" y="18" rx="3" ry="3" width="150" height="15" />
            <rect x="63" y="45" rx="3" ry="3" width="280" height="10" />
            <rect x="113" y="60" rx="3" ry="3" width="230" height="10" />
            </ContentLoader>
        </div>
    </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
    components: {
        ContentLoader,
    }
}
</script>

<style>

</style>